import React from "react";
import {
  Pane,
  Button,
  Heading,
  Paragraph,
  toaster,
  Combobox,
  TextInputField,
  Select,
} from "evergreen-ui";
import facilCard from "../../img/FacilCard.png";
import pendienteFacilCard from "../../img/FacilCardPendiente.png";
import pagadaFacilCard from "../../img/FacilCardPagada.png";
import { AnimatePresence, motion } from "framer-motion";
import CardNameChange from "./cardNameChange";
import { useEffect, useRef } from "react";
import { AiFillEdit, AiOutlineCheckCircle } from "react-icons/ai";
import { BsHouseAdd } from "react-icons/bs";
import checkImg from "../../img/check.jpg";
import axios from "axios";
import EditAddress from "./EditAddress";
import logoImpuls from "../../img/LogoImpuls.png";
import AddAddressDialog from "./AddAddressDialog";
import AddCardDialog from "./AddCardDialog";
import AddFileAndSend from "./AddFileAndSend";
import { RiErrorWarningLine } from "react-icons/ri";
import btc1X from "../../img/Manu071BTC.jpeg"; //ok
import btc2X from "../../img/Manu071BTC.jpeg"; //ok
import erc1X from "../../img/Manu071BEP20.jpeg"; //ok
import erc2X from "../../img/Manu071BEP20.jpeg"; //ok
import trc1X from "../../img/Manu071USDT.jpeg"; //ok
import trc2X from "../../img/Manu071USDT.jpeg"; //ok

const Impulspay = (props) => {
  const [showCardNameChange, setShowCardNameChange] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState({
    cardNumber: "aaa",
  });

  const [imgBtc, setImgBtc] = React.useState(null);
  const [imgErc, setImgErc] = React.useState(null);
  const [imgTrc, setImgTrc] = React.useState(null);

  const [showActivateCardDialog, setShowActivateCardDialog] =
    React.useState(false);

  const [showAddCardDialog, setShowAddCardDialog] = React.useState(false);

  const [selectedCard2, setSelectedCard2] = React.useState({
    cardNumber: "aaa",
  });

  const [selectedNetwork, setSelectedNetwork] = React.useState("none");

  const [showAddAddress, setShowAddAddress] = React.useState(false);

  const [showAddFileAndSend, setShowAddFileAndSend] = React.useState(false);

  const [showEditAddress, setShowEditAddress] = React.useState(false);

  const [selectedUser, setSelectedUser] = React.useState(props.user);

  const [tipoDeposito, setTipoDeposito] = React.useState("spei");

  const [usdWallet, setUsdWallet] = React.useState(0);
  const [btcWallet, setBtcWallet] = React.useState(0);
  const [usdtWallet, setUsdtWallet] = React.useState(0);

  const [monto, setMonto] = React.useState(0);
  const [filteredCards, setFilteredCards] = React.useState([
    {
      cardNumber: "aaa",
    },
  ]);
  const [pendingCards, setPendingCards] = React.useState([
    {
      cardNumber: "aaa",
    },
  ]);

  const filterCards = (cards) => {
    const tarjetas = cards.filter((card) => card.status === "Autorizada");
    return tarjetas;
  };
  const pendCards = (cards) => {
    return cards.filter((card) => card.status === "pending");
  };

  const handleSetDefaultAddress = (address) => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/users/" +
          selectedUser._id +
          "/addressess/" +
          address._id +
          "/setdefault"
      )
      .then((res) => {
        setSelectedUser(res.data.user);
      });
  };

  const handleSendDepositRequest = (file) => {
    toaster.success("Solicitud de depósito enviada con éxito");

    const formData = new FormData();
    formData.append("user", selectedUser._id);
    formData.append("card", selectedCard._id);
    formData.append("last4", selectedCard.last4);
    formData.append("tipoDeposito", tipoDeposito);
    formData.append("monto", monto);
    formData.append("file", file[0]);
    formData.append("correo", selectedUser.correo);
    formData.append("nombre", selectedUser.nombre);

    axios
      .post(
        process.env.REACT_APP_API_URL + "/impuls/depositrequests",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setMonto(0);
        setShowAddFileAndSend(false);
        toaster.success("Solicitud de depósito enviada con éxito");
      })
      .catch((err) => {
        toaster.danger("Ocurrió un error al enviar la solicitud de depósito");
      });
  };

  const handleActivateCard = (card, file) => {
    const formData = new FormData();
    formData.append("user", selectedUser._id);
    formData.append("card", card._id);
    formData.append("file", file[0]);
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "/solicitudesTarjetas/" +
          selectedUser._id +
          "/" +
          card._id +
          "/pagar",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setSelectedUser(res.data.user);
        setShowActivateCardDialog(false);
        props.onUpdateUser(res.data.user);
        toaster.success(
          "Tarjeta pagada con exito, favor de esperar a que sea autorizada"
        );
        setSelectedCard2({});
      });
  };

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/settings/").then((res) => {
      console.log(res.data);
      if (res.data.btc_wallet === 1) {
        setBtcWallet("1NgSqJjBzW7Z8YHgGM6Z1LzwgGVzvFtHgs");
        setImgBtc(btc1X);
      } else {
        setBtcWallet("1NgSqJjBzW7Z8YHgGM6Z1LzwgGVzvFtHgs");
        setImgBtc(btc2X);
      }
      if (res.data.usdt_wallet === 1) {
        setUsdWallet("TADe8WMhi2XVRa7RxYHkwTzWkWDBsUDndK");
        setImgTrc(trc1X);
      } else {
        setUsdWallet("TADe8WMhi2XVRa7RxYHkwTzWkWDBsUDndK"); //USDT (RED TRON) TRX
        setImgTrc(trc2X);
      }
      if (res.data.usdterc_wallet === 1) {
        setUsdtWallet("0xe5b45bad218cd39e7c2cac1c6db93f82de7539ea"); //USDT Red Binance Smart Chain (BEP20) BSC
        setImgErc(erc1X);
      } else {
        setUsdtWallet("0xe5b45bad218cd39e7c2cac1c6db93f82de7539ea"); //USDT Red Binance Smart Chain (BEP20) BSC
        setImgErc(erc2X);
      }
    });
    setFilteredCards(filterCards(selectedUser.cards));
    setPendingCards(pendCards(selectedUser.cards));
    setSelectedUser(props.user);
  }, [props.user]);

  useEffect(() => {
    setFilteredCards(filterCards(selectedUser.cards));
    setPendingCards(pendCards(selectedUser.cards));
  }, [selectedUser.cards]);

  const [margin, setMargin] = React.useState("row");

  useEffect(() => {
    const handleResize = () => {
      setMargin(window.innerWidth > 768 ? "row" : "column");
    };
    window.addEventListener("resize", handleResize);
    setSelectedUser(props.user);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <motion.div
      key="impulspay"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Pane
        display="flex"
        alignItems="left"
        justifyContent="left"
        padding={30}
        className="impuls-header"
        width="100%"
        marginBottom={20}
        style={{
          color: "white",
        }}
      >
        <Heading size={900} color="white">
          <img
            src={logoImpuls}
            style={{
              height: "50px",
              display: "inline-block",
              marginRight: "10px",
            }}
            alt="logo"
          />
        </Heading>
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        marginLeft={margin === "row" ? "40px" : "10px"}
        marginRight="10px"
        style={{
          color: "white",
        }}
      >
        <Paragraph size={600} color="#e1e4f4">
          Hola, {props.user.nombre} {props.user.apellido}, bienvenido a
          Impulspay, aqui podras administrar tus tarjetas y direcciones de
          envio.
        </Paragraph>

        <Pane marginTop="20px" flexDirection="column" alignItems="center">
          <Heading size={600} color="#e1e4f4" marginBottom="20px">
            Tarjetas
          </Heading>
          <Paragraph size={500} color="#e1e4f4">
            Aqui podras administrar tus tarjetas.
          </Paragraph>
          <Paragraph size={500} color="#e1e4f4">
            Haz click en una tarjeta para cambiar su nombre
          </Paragraph>
          {props.user.cards.length === 0 && (
            <Paragraph>No tienes tarjetas registradas.</Paragraph>
          )}
          <Pane
            display="flex"
            flexDirection={margin}
            flexWrap="wrap"
            overflowX="auto"
            overflowY="hidden"
            width="100%"
            gap="30px"
            padding="20px"
            alignItems="center"
          >
            {showCardNameChange && (
              <CardNameChange
                setShowCardNameChange={setShowCardNameChange}
                selectedUser={selectedUser}
                selectedCard={selectedCard}
              />
            )}
            {showAddFileAndSend && (
              <AddFileAndSend
                setShowAddFileAndSend={setShowAddFileAndSend}
                selectedUser={selectedUser}
                onUpdateFileAndSend={(file) => {
                  handleSendDepositRequest(file);
                }}
              />
            )}
            {showActivateCardDialog && (
              <AddFileAndSend
                setShowAddFileAndSend={setShowActivateCardDialog}
                selectedUser={selectedUser}
                onUpdateFileAndSend={(file) => {
                  handleActivateCard(selectedCard2, file);
                }}
              />
            )}
            {selectedUser.cards.map((card, index) => {
              return (
                <motion.div
                  key={index}
                  style={{
                    borderRadius: "5px",
                    height: "376px",
                    backgroundImage:
                      card.status === "pending"
                        ? `url(${pendienteFacilCard})`
                        : card.status === "Pagada"
                        ? `url(${pagadaFacilCard})`
                        : `url(${facilCard})`,

                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    userSelect: "none",
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px",
                  }}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  whileHover={{ scale: 1.02, cursor: "pointer" }}
                  whileTap={{ scale: 0.98 }}
                  className="card"
                  onClick={() => {
                    setSelectedCard(card);
                    setShowCardNameChange(true);
                  }}
                >
                  <Paragraph
                    fontSize="15px"
                    marginTop="35px"
                    marginLeft="10px"
                    color="#000"
                    textAlign="left"
                    fontWeight="bold"
                  >
                    {card.nombre}
                  </Paragraph>

                  <Paragraph
                    fontSize="20px"
                    marginTop="100px"
                    marginLeft="15px"
                    color="000"
                    fontWeight="bold"
                  >
                    **** **** **** {card.last4}
                  </Paragraph>

                  <Paragraph
                    fontSize="15px"
                    marginTop="5px"
                    marginLeft="10px"
                    color="000"
                    fontWeight="bold"
                  >
                    renovar antes de:{" "}
                    {card.fecha_renovacion
                      ? card.fecha_renovacion.substring(0, 10)
                      : "N/A"}
                  </Paragraph>
                </motion.div>
              );
            })}
            <motion.div
              style={{
                borderRadius: "10px",
                padding: "10px",
                height: "376px",
                width: "236px",
                backgroundImage: `url(${facilCard})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                userSelect: "none",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#9caae2",
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Button
                style={{ marginTop: "100px" }}
                appearance="minimal"
                iconBefore="plus"
                color="000"
                fontWeight="bold"
                onClick={() => setShowAddCardDialog(true)}
              >
                Solicitar mas tarjetas
              </Button>
            </motion.div>
          </Pane>
          <Pane marginTop="10px">
            <Heading size={600} color="#e1e4f4">
              Direcciones
            </Heading>
            <Paragraph size={500} color="#e1e4f4">
              Aqui podras administrar tus direcciones.
            </Paragraph>

            <Pane
              display="flex"
              flexDirection={margin}
              marginTop="20px"
              flexWrap="wrap"
              gap="20px"
              padding="20px"
            >
              {showEditAddress && (
                <EditAddress
                  showEditAddress={showEditAddress}
                  setShowEditAddress={setShowEditAddress}
                  selectedUser={selectedUser}
                  selectedAddress={selectedCard}
                  onUpdateAddress={(user) => {
                    setSelectedUser(user);
                  }}
                />
              )}
              {selectedUser.addressess.map((address, index) => {
                return (
                  <motion.div
                    layout
                    key={index}
                    style={{
                      borderRadius: "10px",
                      padding: "20px",
                      minWidth: "250px",
                      userSelect: "none",
                      backgroundColor: "#9caae2",
                    }}
                    initial={{ scale: 0 }}
                    animate={{
                      scale: 1,
                      width: "250px",
                    }}
                  >
                    <Paragraph
                      size={800}
                      style={{
                        textAlign: "center",
                        marginBottom: "10px",
                        color: "black",
                      }}
                    >
                      {address.nombre} {address.apellido}
                    </Paragraph>
                    <Paragraph size={400}>
                      Dirección: {address.direccion}
                    </Paragraph>
                    <Paragraph size={400}>Ciudad: {address.ciudad}</Paragraph>
                    <Paragraph size={400}>Estado: {address.estado}</Paragraph>
                    <Paragraph size={400}>País: {address.pais}</Paragraph>
                    <Paragraph size={400}>
                      Código Postal: {address.codigo_postal}
                    </Paragraph>
                    <Paragraph size={400}>
                      Teléfono: {address.telefono}
                    </Paragraph>

                    <Pane
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button
                        appearance="minimal"
                        iconBefore={AiFillEdit}
                        color="blue"
                        onClick={() => {
                          setSelectedCard(address);
                          setShowEditAddress(true);
                        }}
                      >
                        Editar
                      </Button>
                      {address.predeterminada ? (
                        <Heading
                          size={300}
                          style={{
                            transform: "translateY(-10px)",
                            color: "green",
                          }}
                        >
                          {" "}
                          Predeterminada{" "}
                          {
                            <img
                              src={checkImg}
                              style={{
                                width: 30,
                                height: 30,
                                display: "inline-block",
                                transform: "translateY(10px)",
                              }}
                              alt="check"
                            />
                          }{" "}
                        </Heading>
                      ) : (
                        <Button
                          appearance="minimal"
                          iconBefore={AiOutlineCheckCircle}
                          color="blue"
                          onClick={() => {
                            handleSetDefaultAddress(address);
                            toaster.success(
                              "Dirección predeterminada cambiada con éxito"
                            );
                          }}
                        >
                          Hacer predeterminada
                        </Button>
                      )}
                    </Pane>
                  </motion.div>
                );
              })}
              {showAddAddress && (
                <AddAddressDialog
                  showAddAddress={showAddAddress}
                  setShowAddAddress={setShowAddAddress}
                  selectedUser={selectedUser}
                  onUpdateAddress={(user) => {
                    setSelectedUser(user);
                  }}
                />
              )}

              {showAddCardDialog && (
                <AddCardDialog
                  showAddCardDialog={showAddCardDialog}
                  setShowAddCardDialog={setShowAddCardDialog}
                  selectedUser={selectedUser}
                  onUpdateCard={(user) => {
                    setSelectedUser(user);
                  }}
                />
              )}

              <Pane
                style={{
                  borderRadius: "5px",
                  padding: "20px",
                  minWidth: "200px",
                  userSelect: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#9caae2",
                  flexDirection: "column",
                }}
              >
                <BsHouseAdd
                  style={{
                    fontSize: "80px",
                    color: "black",
                    marginBottom: "10px",
                    cursor: "pointer",
                  }}
                  alt="check"
                  onClick={() => setShowAddAddress(true)}
                />
                <Paragraph size={400} color="black">
                  Agregar dirección
                </Paragraph>
              </Pane>
            </Pane>
          </Pane>
          <Pane>
            <Heading size={600} color="#e1e4f4">
              Solicitar depositos
            </Heading>
            <Paragraph size={500} color="#e1e4f4">
              Aqui podras solicitar depositos a tus tarjetas.
            </Paragraph>

            <Pane
              display="flex"
              flexDirection="row"
              marginTop="20px"
              flexWrap="wrap"
              marginLeft="20px"
              marginRight="20px"
              padding="20px"
              backgroundColor="#9caae2"
              borderRadius="10px"
            >
              <Pane
                display="flex"
                flexDirection={margin}
                gap="40px"
                alignItems="stretch"
                width="100%"
              >
                <Pane display="flex" flexDirection="column" gap="20px">
                  <Heading>Seleccione una tarjeta:</Heading>
                  <Combobox
                    items={filteredCards}
                    itemToString={(item) =>
                      item ? item.nombre + " - " + item.last4 : ""
                    }
                    onChange={(selected) => {
                      setSelectedCard(selected);
                    }}
                    placeholder="Seleccione una tarjeta"
                  />
                  {selectedCard && (
                    <Pane>
                      <Heading>Nombre de la tarjeta:</Heading>
                      <Paragraph>{selectedCard.nombre}</Paragraph>
                      <Paragraph>** {selectedCard.last4}</Paragraph>
                    </Pane>
                  )}
                </Pane>
                <Pane // Seccion de tipo de deposito
                  display="flex"
                  flexDirection="column"
                  gap="20px"
                  flexWrap="wrap"
                  alignItems="stretch"
                >
                  <Heading>
                    Seleccione el tipo de deposito que desea solicitar:
                  </Heading>
                  <Pane
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="row"
                    gap="20px"
                    alignItems="stretch"
                  >
                    <motion.div
                      style={{
                        backgroundColor:
                          tipoDeposito === "spei" ? "#1d3286" : "white",
                        borderRadius: "10px",
                        padding: "20px",
                        minWidth: "",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      initial={{ scale: 0 }}
                      animate={{
                        scale: 1,
                      }}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setTipoDeposito("spei")}
                    >
                      <Heading
                        style={{
                          textAlign: "center",
                          color: tipoDeposito === "spei" ? "white" : "black",
                        }}
                      >
                        Deposito SPEI (Nacional)
                      </Heading>
                    </motion.div>
                    <motion.div
                      style={{
                        backgroundColor:
                          tipoDeposito === "btc" ? "#1d3286" : "white",
                        borderRadius: "10px",
                        padding: "20px",
                        minWidth: "",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      initial={{ scale: 0 }}
                      animate={{
                        scale: 1,
                      }}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setTipoDeposito("btc")}
                    >
                      <Heading
                        style={{
                          textAlign: "center",
                          color: tipoDeposito === "btc" ? "white" : "black",
                        }}
                      >
                        Deposito Internacional (BTC)
                      </Heading>
                    </motion.div>
                    <motion.div
                      style={{
                        backgroundColor:
                          tipoDeposito === "usdt" ? "#1d3286" : "white",
                        borderRadius: "10px",
                        padding: "20px",
                        minWidth: "",
                        userSelect: "none",
                        cursor: "pointer",
                      }}
                      initial={{ scale: 0 }}
                      animate={{
                        scale: 1,
                      }}
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.98 }}
                      onClick={() => setTipoDeposito("usdt")}
                    >
                      <Heading
                        style={{
                          textAlign: "center",
                          color: tipoDeposito === "usdt" ? "white" : "black",
                        }}
                      >
                        Deposito Internacional (USDT)
                      </Heading>
                    </motion.div>
                  </Pane>
                </Pane>
                <Pane display="flex" flexDirection="column" gap="20px">
                  <Heading>Seleccione el monto que desea solicitar:</Heading>
                  <Pane
                    display="flex"
                    flexDirection="row"
                    alignItems="stretch"
                    gap="10px"
                  >
                    <TextInputField
                      value={monto}
                      onChange={(e) => setMonto(e.target.value)}
                      placeholder="Monto"
                      type="number"
                    />
                    {tipoDeposito === "spei" ? (
                      <Heading
                        style={{
                          padding: "10px",
                        }}
                      >
                        Pesos Mexicanos (MXN)
                      </Heading>
                    ) : (
                      <Heading
                        style={{
                          padding: "10px",
                        }}
                      >
                        Dolares (USD)
                      </Heading>
                    )}
                  </Pane>
                  {tipoDeposito === "spei" ? (
                    <Paragraph
                      style={{
                        color: monto < 9999 ? "red" : "green",
                      }}
                    >
                      
                    </Paragraph>
                  ) : (
                    <Paragraph
                      style={{
                        color: monto < 499 ? "red" : "green",
                      }}
                    >
                      
                    </Paragraph>
                  )}
                </Pane>
              </Pane>
            </Pane>
            <motion.div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                width: window.innerWidth > 768 ? "50vw" : "90vw",
                margin: "auto",
                marginTop: "20px",
              }}
              layout
            >
              <AnimatePresence mode="wait">
                {tipoDeposito === "spei" ? (
                  <motion.div
                    style={{
                      backgroundColor: "#1d3286",
                      borderRadius: "10px",
                      padding: "20px",
                      color: "white",
                      fontFamily:
                        "-apple-system, BlinkMacSystemFont, sans-serif, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{ opacity: 0 }}
                  >
                    <h3>Datos para realizar el depósito SPEI:</h3>
                    <p>
                      Para realizar el depósito SPEI, favor de realizar la
                      transferencia a la siguiente cuenta:
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <h3>Numero de cuenta:</h3>
                      <p>456434340201</p>
                      <h3>CLABE:</h3>
                      <p>030320900042104060</p>
                      <h3>Beneficiario:</h3>
                      <p>VHM IMPULS SAPI DE CV</p>
                      <h3>Banco:</h3>
                      <p>BANBAJIO</p>
                    </div>
                    <p
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      Monto a depositar: {monto} MXN
                    </p>
                  </motion.div>
                ) : tipoDeposito === "btc" ? (
                  <motion.div
                    style={{
                      backgroundColor: "#1d3286",
                      borderRadius: "10px",
                      padding: "20px",
                      color: "white",
                      fontFamily: "sans-serif",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{ opacity: 0 }}
                  >
                    <h3>Datos para realizar el depósito BTC:</h3>
                    <p>
                      Para realizar el depósito BTC, favor de realizar la
                      transferencia a la siguiente cuenta:
                    </p>
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#E4E2E2",
                        borderRadius: "10px",
                        marginLeft: "60px",
                        marginRight: "60px",
                        marginBottom: "20px",
                        marginTop: "20px",
                        color: "black",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        padding: "20px",
                      }}
                    >
                      <div style={{ marginLeft: "20px" }}>
                        <RiErrorWarningLine size={40} />
                      </div>
                      <div>
                        Deposita unicamente Bitcoin (BTC) a esta dirección.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <p>Codigo QR</p>
                      <img src={imgBtc} alt="QR" width={200} />
                      <p>Direccion BTC</p>
                      <p
                        onClick={() => {
                          navigator.clipboard.writeText(btcWallet);
                          toaster.success("Direccion copiada al portapapeles");
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {btcWallet}
                      </p>
                    </div>
                    <p
                      style={{
                        marginTop: "20px",
                      }}
                    >
                      Monto a depositar: {monto} USD
                    </p>
                  </motion.div>
                ) : (
                  <motion.div
                    style={{
                      backgroundColor: "#1d3286",
                      borderRadius: "10px",
                      padding: "20px",
                      color: "white",
                      fontFamily: "sans-serif",
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                    }}
                    exit={{ opacity: 0 }}
                  >
                    <Pane display="flex" flexDirection="column" gap="20px">
                      <Heading color="white">
                        Seleccione la red para deposito USDT:
                      </Heading>
                      <Pane
                        display="flex"
                        flexDirection="row"
                        alignItems="stretch"
                        gap="10px"
                      >
                        <motion.div
                          style={{
                            backgroundColor:
                              selectedNetwork === "tron" ? "#9caae2" : "white",
                            borderRadius: "10px",
                            fontWeight:
                              selectedNetwork === "tron" ? "bold" : "normal",
                            color:
                              selectedNetwork === "tron" ? "white" : "black",
                            padding: "20px",
                            minWidth: "",
                            userSelect: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedNetwork("tron");
                          }}
                        >
                          TRX (Red Tron)
                        </motion.div>
                        <motion.div
                          style={{
                            backgroundColor:
                              selectedNetwork === "ethereum"
                                ? "#9caae2"
                                : "white",
                            color:
                              selectedNetwork === "ethereum"
                                ? "white"
                                : "black",
                            fontWeight:
                              selectedNetwork === "ethereum"
                                ? "bold"
                                : "normal",
                            borderRadius: "10px",
                            padding: "20px",
                            minWidth: "",
                            userSelect: "none",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setSelectedNetwork("ethereum");
                          }}
                        >
                          USDT Red Binance Smart Chain (BEP20) BSC
                        </motion.div>
                      </Pane>
                    </Pane>
                    {selectedNetwork === "tron" ? (
                      <>
                        <h3>Datos para realizar el depósito USDT:</h3>
                        <p>
                          Para realizar el depósito USDT, favor de realizar la
                          transferencia a la siguiente cuenta:
                        </p>
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "#E4E2E2",
                            borderRadius: "10px",
                            marginLeft: "60px",
                            marginRight: "60px",
                            marginBottom: "20px",
                            marginTop: "20px",
                            color: "black",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            padding: "20px",
                          }}
                        >
                          <div style={{ marginLeft: "20px" }}>
                            <RiErrorWarningLine size={40} />
                          </div>
                          <div>
                            Deposita unicamente Tether (USDT) desde una direccion en Red Ethereum (ERC20) a esta direccion.<strong> Los depositos desde otras redes no seran acreditados. </strong>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            marginTop: "20px",
                          }}
                        >
                          <img src={imgTrc} alt="QR" width={200} />
                          <p>Direccion USDT</p>
                          <p
                            onClick={() => {
                              navigator.clipboard.writeText(usdWallet);
                              toaster.success(
                                "Direccion copiada al portapapeles"
                              );
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {usdWallet}
                          </p>
                        </div>
                        <p
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          Monto a depositar: {monto} USD
                        </p>
                      </>
                    ) : (
                      <>
                        <h3>Datos para realizar el depósito USDT:</h3>
                        <p>
                          Para realizar el depósito USDT Red Binance Smart Chain, favor
                          de realizar la transferencia a la siguiente cuenta:
                        </p>
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "#E4E2E2",
                            borderRadius: "10px",
                            marginLeft: "60px",
                            marginRight: "60px",
                            marginBottom: "20px",
                            marginTop: "20px",
                            color: "black",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            padding: "20px",
                          }}
                        >
                          <div style={{ marginLeft: "20px" }}>
                            <RiErrorWarningLine size={40} />
                          </div>
                          <div>
                          Deposita unicamente USDT Red Binance Smart Chain (BEP20) BSC desde una
                            direccion en BEP20 a esta direccion. <strong>Los
                            depositos desde otras redes no seran acreditados.</strong>

                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                            marginTop: "20px",
                          }}
                        >
                          <img src={imgErc} alt="QR" width={200} />
                          <p>Direccion USDT</p>
                          <p
                            onClick={() => {
                              navigator.clipboard.writeText(usdtWallet);
                              toaster.success(
                                "Direccion copiada al portapapeles"
                              );
                            }}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {usdtWallet}
                          </p>
                        </div>
                        <p
                          style={{
                            marginTop: "20px",
                          }}
                        >
                          Monto a depositar: {monto} USD
                        </p>
                      </>
                    )}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
            <Pane
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              justifyContent="center"
              marginTop="20px"
              gap="20px"
            >
              <Button
                appearance="primary"
                intent="success"
                onClick={() => {
                  // if (tipoDeposito === "spei" && monto < 9999) {
                  //   toaster.danger(
                  //     "El monto minimo para deposito SPEI es de 10,000 MXN"
                  //   );
                  //   return;
                  // }
                  // if (tipoDeposito === "btc" && monto < 499) {
                  //   toaster.danger(
                  //     "El monto minimo para deposito BTC/USDT es de 500 USD"
                  //   );
                  //   return;
                  // }
                  if(monto < 0 || !monto){
                    toaster.danger("Introduzca por favor el monto solicitado");
                    return;
                  }
                  if (selectedCard === null) {
                    toaster.danger("Seleccione una tarjeta");
                    return;
                  }
                  if (monto == 0) {
                    toaster.danger("Seleccione un monto");
                    return;
                  }
                  setShowAddFileAndSend(true);
                }}
              >
                Adjuntar comprobante de transferencia
              </Button>

              <Button
                appearance="minimal"
                intent="danger"
                onClick={() => {
                  props.setShowImpulspay(false);
                }}
              >
                Cancelar
              </Button>
            </Pane>
          </Pane>
          <Pane>
            <Heading size={600} color="#e1e4f4" marginTop="20px">
              Pagar tarjetas
            </Heading>
            <Paragraph size={500} color="#e1e4f4">
              En este modulo deberás seleccionar tu tarjeta y adjuntar el
              comprobante de pago.
            </Paragraph>
            <Pane
              display="flex"
              flexDirection="row"
              marginTop="20px"
              flexWrap="wrap"
              marginLeft="20px"
              marginRight="20px"
              padding="20px"
              backgroundColor="#9caae2"
              borderRadius="10px"
              alignItems="center"
              justifyContent="center"
              width={window.innerWidth > 768 ? "30vw" : "90vw"}
            >
              <Pane
                display="flex"
                flexDirection={margin}
                gap="40px"
                alignItems="stretch"
              >
                <Pane
                  display="flex"
                  flexDirection="column"
                  gap="20px"
                  alignItems="stretch"
                >
                  {pendingCards.length > 0 ? (
                    <>
                      <Heading>Seleccione una tarjeta:</Heading>
                      <Combobox
                        items={pendingCards}
                        itemToString={(item) =>
                          item ? item.nombre + " - " + item.last4 : ""
                        }
                        onChange={(selected) => {
                          setSelectedCard2(selected);
                        }}
                        placeholder="Seleccione una tarjeta"
                      />
                      {selectedCard2 && (
                        <Pane>
                          <Heading>Nombre de la tarjeta:</Heading>
                          <Paragraph>{selectedCard2.nombre}</Paragraph>
                          <Paragraph>** {selectedCard2.last4}</Paragraph>
                        </Pane>
                      )}
                      <Button
                        appearance="primary"
                        intent="success"
                        onClick={() => {
                          if (selectedCard2 === null) {
                            toaster.danger("Seleccione una tarjeta");
                            return;
                          }
                          setShowActivateCardDialog(true);
                        }}
                      >
                        Adjuntar comprobante de pago
                      </Button>
                    </>
                  ) : (
                    <Paragraph>No tienes tarjetas por pagar.</Paragraph>
                  )}
                </Pane>
              </Pane>
            </Pane>
          </Pane>
        </Pane>
      </Pane>
      <footer>
        <Pane
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={20}
          className="impuls-footer"
          width="100%"
          marginTop={20}
          style={{
            color: "white",
          }}
        >
          <Paragraph size={400} color="#e1e4f4">
            © 2021 Impulspay. Todos los derechos reservados.
          </Paragraph>
        </Pane>
      </footer>
    </motion.div>
  );
};

export default Impulspay;
